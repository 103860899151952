import React from "react";

export const BedIcon = ({ className, style }) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4052 6.75L10.5922 6.75C10.3496 6.75 10.2283 6.75 10.1145 6.76862C9.59137 6.85418 9.1107 7.23003 8.7718 7.81856C8.69804 7.94663 8.63077 8.09407 8.49623 8.38894C8.2886 8.84399 8.18478 9.07152 8.14228 9.24532C7.93812 10.0802 8.27148 10.9903 8.86019 11.2053C8.98274 11.25 9.16989 11.25 9.5442 11.25L27.4532 11.25C27.8275 11.25 28.0147 11.25 28.1372 11.2053C28.7259 10.9903 29.0593 10.0802 28.8551 9.24532C28.8126 9.07153 28.7088 8.84394 28.5012 8.38894C28.3666 8.09411 28.2993 7.94662 28.2256 7.81856C27.8867 7.23003 27.406 6.85418 26.8829 6.76862C26.769 6.75 26.6478 6.75 26.4052 6.75Z"
        stroke="#D9D9D9"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M13.8125 19.6875L25.7917 11.25"
        stroke="#D9D9D9"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M23.1862 19.6875L11.207 11.25"
        stroke="#D9D9D9"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M14.3327 21.375C14.3327 22.6176 13.3999 23.625 12.2493 23.625C11.0988 23.625 10.166 22.6176 10.166 21.375C10.166 20.1324 11.0988 19.125 12.2493 19.125C13.3999 19.125 14.3327 20.1324 14.3327 21.375Z"
        stroke="#D9D9D9"
        stroke-width="1.5"
      />
      <path
        d="M26.8327 21.375C26.8327 22.6176 25.8999 23.625 24.7493 23.625C23.5988 23.625 22.666 22.6176 22.666 21.375C22.666 20.1324 23.5988 19.125 24.7493 19.125C25.8999 19.125 26.8327 20.1324 26.8327 21.375Z"
        stroke="#D9D9D9"
        stroke-width="1.5"
      />
      <path
        d="M12.25 6.75V4.61655C12.25 3.80923 12.8869 3.21686 13.5443 3.41266L15.6276 4.03313C16.0914 4.17124 16.4167 4.66759 16.4167 5.23702V6.75"
        stroke="#D9D9D9"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <line
        x1="5.53906"
        y1="14.75"
        x2="12.0006"
        y2="14.75"
        stroke="#D9D9D9"
        stroke-width="0.5"
      />
      <line
        x1="2.76953"
        y1="15.75"
        x2="11.0772"
        y2="15.75"
        stroke="#D9D9D9"
        stroke-width="0.5"
      />
      <line
        y1="16.75"
        x2="10.1538"
        y2="16.75"
        stroke="#D9D9D9"
        stroke-width="0.5"
      />
    </svg>
  );
};
export const PatientRecieved = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_739_568)">
        <path
          d="M5.49935 7.10417H3.43685C2.79722 7.10417 2.4774 7.10417 2.21716 7.18311C1.63122 7.36085 1.1727 7.81938 0.994959 8.40531C0.916016 8.66555 0.916016 8.98537 0.916016 9.625M7.33268 8.25L8.24935 9.16667L10.0827 7.33333M6.64518 3.4375C6.64518 4.57659 5.72177 5.5 4.58268 5.5C3.44359 5.5 2.52018 4.57659 2.52018 3.4375C2.52018 2.29841 3.44359 1.375 4.58268 1.375C5.72177 1.375 6.64518 2.29841 6.64518 3.4375Z"
          stroke="#474747"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_739_568">
          <rect width="11" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const WheelIn = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1682_1389)">
        <path
          d="M16.6904 6.2028L21.1851 6.06651M21.1851 6.06651L19.5829 7.23318M21.1851 6.06651L19.5185 4.99896M16.771 8.99557L23.3088 8.79733M23.3088 8.79733L21.7065 9.964M23.3088 8.79733L21.6421 7.72979"
          stroke="#48B16E"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <path
        d="M12.2439 3.75L2.75609 3.75C2.61056 3.75 2.53779 3.75 2.46948 3.76034C2.1556 3.80788 1.8672 4.01669 1.66386 4.34364C1.61961 4.4148 1.57924 4.4967 1.49852 4.66052C1.37394 4.91333 1.31165 5.03973 1.28615 5.13629C1.16365 5.60013 1.36367 6.10574 1.71689 6.22514C1.79042 6.25 1.90272 6.25 2.1273 6.25L12.8727 6.25C13.0973 6.25 13.2096 6.25 13.2831 6.22514C13.6363 6.10574 13.8363 5.60013 13.7138 5.13629C13.6884 5.03974 13.626 4.9133 13.5015 4.66052C13.4208 4.49673 13.3804 4.41479 13.3361 4.34364C13.1328 4.01669 12.8444 3.80788 12.5305 3.76034C12.4622 3.75 12.3894 3.75 12.2439 3.75Z"
        stroke="#474747"
        stroke-linecap="round"
      />
      <path
        d="M4.6875 10.9375L11.875 6.25"
        stroke="#474747"
        stroke-linecap="round"
      />
      <path
        d="M10.3125 10.9375L3.125 6.25"
        stroke="#474747"
        stroke-linecap="round"
      />
      <path
        d="M5 11.875C5 12.5654 4.44036 13.125 3.75 13.125C3.05964 13.125 2.5 12.5654 2.5 11.875C2.5 11.1846 3.05964 10.625 3.75 10.625C4.44036 10.625 5 11.1846 5 11.875Z"
        stroke="#474747"
      />
      <path
        d="M12.5 11.875C12.5 12.5654 11.9404 13.125 11.25 13.125C10.5596 13.125 10 12.5654 10 11.875C10 11.1846 10.5596 10.625 11.25 10.625C11.9404 10.625 12.5 11.1846 12.5 11.875Z"
        stroke="#474747"
      />
      <path
        d="M3.75 3.75V2.56475C3.75 2.11624 4.13212 1.78714 4.52658 1.89592L5.77659 2.24063C6.05481 2.31735 6.25 2.59311 6.25 2.90945V3.75"
        stroke="#474747"
        stroke-linecap="round"
      />
      <defs>
        <clipPath id="clip0_1682_1389">
          <rect
            width="9.81115"
            height="6.70543"
            fill="white"
            transform="matrix(0.999541 -0.0303078 0.0288363 0.999584 15 4.29736)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const WheelOut = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2439 3.75L12.7561 3.75C12.6106 3.75 12.5378 3.75 12.4695 3.76034C12.1556 3.80788 11.8672 4.01669 11.6639 4.34364C11.6196 4.4148 11.5792 4.4967 11.4985 4.66052C11.3739 4.91333 11.3117 5.03973 11.2862 5.13629C11.1637 5.60013 11.3637 6.10574 11.7169 6.22514C11.7904 6.25 11.9027 6.25 12.1273 6.25L22.8727 6.25C23.0973 6.25 23.2096 6.25 23.2831 6.22514C23.6363 6.10574 23.8363 5.60013 23.7138 5.13629C23.6884 5.03974 23.626 4.9133 23.5015 4.66052C23.4208 4.49673 23.3804 4.41479 23.3361 4.34364C23.1328 4.01669 22.8444 3.80788 22.5305 3.76034C22.4622 3.75 22.3894 3.75 22.2439 3.75Z"
        stroke="#474747"
        stroke-linecap="round"
      />
      <path
        d="M14.6875 10.9375L21.875 6.25"
        stroke="#474747"
        stroke-linecap="round"
      />
      <path
        d="M20.3125 10.9375L13.125 6.25"
        stroke="#474747"
        stroke-linecap="round"
      />
      <path
        d="M15 11.875C15 12.5654 14.4404 13.125 13.75 13.125C13.0596 13.125 12.5 12.5654 12.5 11.875C12.5 11.1846 13.0596 10.625 13.75 10.625C14.4404 10.625 15 11.1846 15 11.875Z"
        stroke="#474747"
      />
      <path
        d="M22.5 11.875C22.5 12.5654 21.9404 13.125 21.25 13.125C20.5596 13.125 20 12.5654 20 11.875C20 11.1846 20.5596 10.625 21.25 10.625C21.9404 10.625 22.5 11.1846 22.5 11.875Z"
        stroke="#474747"
      />
      <path
        d="M13.75 3.75V2.56475C13.75 2.11624 14.1321 1.78714 14.5266 1.89592L15.7766 2.24063C16.0548 2.31735 16.25 2.59311 16.25 2.90945V3.75"
        stroke="#474747"
        stroke-linecap="round"
      />
      <g clip-path="url(#clip0_1682_1390)">
        <path
          d="M8.30957 8.88818L3.81486 8.77137M3.81486 8.77137L5.41707 9.77137M3.81486 8.77137L5.48153 7.85633M8.229 11.282L1.69124 11.1121M1.69124 11.1121L3.29346 12.1121M1.69124 11.1121L3.35791 10.197"
          stroke="#B51616"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1682_1390">
          <rect
            width="9.80995"
            height="5.74838"
            fill="white"
            transform="matrix(-0.999662 -0.0259813 -0.0336373 0.999434 10 7.25488)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const CleaningStart = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.29 16.0278H22.9397V16.6389H21.29V18.1667H20.6301V16.6389H18.9805V16.0278H20.6301V14.5H21.29V16.0278Z"
        fill="#474747"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1338 9.93135C17.9307 10.1294 17.7679 10.3707 17.6837 10.5266L17.1824 10.2234C17.2907 10.0228 17.4887 9.72942 17.7427 9.48185C17.9901 9.24062 18.3349 9 18.7321 9H23.6399V9.61111H21.3304V11.4444H22.4851C22.804 11.4444 23.0625 11.718 23.0625 12.0556V12.6667H23.3512C24.3078 12.6667 25.0833 13.4875 25.0833 14.5V19.0833C25.0833 19.5896 24.6956 20 24.2173 20H17.8661C17.3877 20 17 19.5896 17 19.0833V14.5C17 13.4875 17.7755 12.6667 18.7321 12.6667H19.0208V12.0556C19.0208 11.718 19.2793 11.4444 19.5982 11.4444H20.753V9.61111H18.7321C18.552 9.61111 18.3435 9.72697 18.1338 9.93135ZM17.5774 14.5C17.5774 13.825 18.0944 13.2778 18.7321 13.2778H23.3512C23.9889 13.2778 24.506 13.825 24.506 14.5V19.0833C24.506 19.2521 24.3767 19.3889 24.2173 19.3889H17.8661C17.7066 19.3889 17.5774 19.2521 17.5774 19.0833V14.5ZM22.4851 12.0556V12.6667H19.5982V12.0556H22.4851Z"
        fill="#474747"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0089 12.5025L7.04333 5.50916C6.94752 5.34022 7.01591 5.13045 7.19611 5.04062C7.37626 4.95079 7.59998 5.01493 7.69579 5.18387L11.6614 12.1772L12.2239 11.8968C12.9446 11.5375 13.8394 11.794 14.2227 12.4698L14.4621 12.8919L17 16.3753L11.7354 19L10.0746 15.0789L9.83544 14.657C9.45225 13.9813 9.72585 13.1422 10.4465 12.7829L11.0089 12.5025ZM10.7935 13.3947L12.5708 12.5086C12.9312 12.3289 13.3786 12.4572 13.5702 12.795L13.8094 13.2169L10.7271 14.7536L10.4879 14.3318C10.2963 13.9939 10.4331 13.5743 10.7935 13.3947ZM11.1002 15.623L12.1174 18.0248L12.7951 17.6869L11.9952 16.2265L12.6525 15.91L13.4476 17.3616L15.9241 16.1269L14.3694 13.9931L11.1002 15.623Z"
        fill="#474747"
      />
      <path
        d="M8.91514 16.106C9.10961 15.9442 9.39845 15.9706 9.56042 16.1649C9.72249 16.3594 9.69623 16.6484 9.50176 16.8105L9.50121 16.8109L9.50052 16.8115L9.49892 16.8128L9.49438 16.8165L9.48049 16.8276C9.46908 16.8367 9.45336 16.849 9.43347 16.8639C9.39373 16.8939 9.33717 16.9348 9.26494 16.9829C9.12061 17.0791 8.91243 17.2051 8.64912 17.3305C8.12364 17.5807 7.3684 17.8334 6.45833 17.8334C6.2052 17.8334 6 17.6282 6 17.375C6 17.1219 6.2052 16.9167 6.45833 16.9167C7.19827 16.9167 7.81803 16.711 8.25505 16.5029C8.47298 16.3991 8.64293 16.2959 8.75646 16.2202C8.81311 16.1824 8.85537 16.1517 8.88223 16.1315C8.89561 16.1214 8.90515 16.114 8.91065 16.1096L8.91587 16.1054L8.91514 16.106Z"
        fill="#474747"
      />
      <path
        d="M9.83297 18.916C10.0053 18.7628 10.0519 18.4524 9.937 18.2226C9.82214 17.9929 9.58926 17.9309 9.41695 18.0839L9.41598 18.0848L9.40874 18.091C9.40188 18.097 9.39097 18.1062 9.37631 18.1184C9.34698 18.1428 9.30266 18.1786 9.24554 18.2221C9.1311 18.3093 8.9667 18.4262 8.76956 18.5431C8.36866 18.7806 7.86189 19 7.37499 19C7.16789 19 7 19.2238 7 19.5C7 19.7761 7.16789 20 7.37499 20C8.01309 20 8.6313 19.7193 9.07413 19.4569C9.29887 19.3237 9.48603 19.1906 9.61765 19.0903C9.68361 19.0401 9.73596 18.9978 9.77252 18.9675C9.79082 18.9523 9.80519 18.9401 9.81535 18.9313L9.82742 18.9209L9.83102 18.9177L9.83297 18.916Z"
        fill="#474747"
      />
      <g clip-path="url(#clip0_1682_1391)">
        <path
          d="M17.3342 11.9333C17.3342 12.2279 17.0955 12.4666 16.8009 12.4666C16.5064 12.4666 16.2676 12.2279 16.2676 11.9333C16.2676 11.8626 16.2813 11.7951 16.3063 11.7333C16.3855 11.5378 16.8009 11.1333 16.8009 11.1333C16.8009 11.1333 17.2164 11.5378 17.2955 11.7333C17.3205 11.7951 17.3342 11.8626 17.3342 11.9333Z"
          stroke="#474747"
          stroke-width="0.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1682_1391">
          <rect
            width="1.6"
            height="1.6"
            fill="white"
            transform="translate(16 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const CleaningEnd = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.29 16.0278H22.9397V16.6389H21.29V18.1667H20.6301V16.6389H18.9805V16.0278H20.6301V14.5H21.29V16.0278Z"
        fill="#474747"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1338 9.93135C17.9307 10.1294 17.7679 10.3707 17.6837 10.5266L17.1824 10.2234C17.2907 10.0228 17.4887 9.72942 17.7427 9.48185C17.9901 9.24062 18.3349 9 18.7321 9H23.6399V9.61111H21.3304V11.4444H22.4851C22.804 11.4444 23.0625 11.718 23.0625 12.0556V12.6667H23.3512C24.3078 12.6667 25.0833 13.4875 25.0833 14.5V19.0833C25.0833 19.5896 24.6956 20 24.2173 20H17.8661C17.3877 20 17 19.5896 17 19.0833V14.5C17 13.4875 17.7755 12.6667 18.7321 12.6667H19.0208V12.0556C19.0208 11.718 19.2793 11.4444 19.5982 11.4444H20.753V9.61111H18.7321C18.552 9.61111 18.3435 9.72697 18.1338 9.93135ZM17.5774 14.5C17.5774 13.825 18.0944 13.2778 18.7321 13.2778H23.3512C23.9889 13.2778 24.506 13.825 24.506 14.5V19.0833C24.506 19.2521 24.3767 19.3889 24.2173 19.3889H17.8661C17.7066 19.3889 17.5774 19.2521 17.5774 19.0833V14.5ZM22.4851 12.0556V12.6667H19.5982V12.0556H22.4851Z"
        fill="#474747"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0089 12.5025L7.04333 5.50916C6.94752 5.34022 7.01591 5.13045 7.19611 5.04062C7.37626 4.95079 7.59998 5.01493 7.69579 5.18387L11.6614 12.1772L12.2239 11.8968C12.9446 11.5375 13.8394 11.794 14.2227 12.4698L14.4621 12.8919L17 16.3753L11.7354 19L10.0746 15.0789L9.83544 14.657C9.45225 13.9813 9.72585 13.1422 10.4465 12.7829L11.0089 12.5025ZM10.7935 13.3947L12.5708 12.5086C12.9312 12.3289 13.3786 12.4572 13.5702 12.795L13.8094 13.2169L10.7271 14.7536L10.4879 14.3318C10.2963 13.9939 10.4331 13.5743 10.7935 13.3947ZM11.1002 15.623L12.1174 18.0248L12.7951 17.6869L11.9952 16.2265L12.6525 15.91L13.4476 17.3616L15.9241 16.1269L14.3694 13.9931L11.1002 15.623Z"
        fill="#474747"
      />
      <path
        d="M17.1673 6.25L14.8757 8.54167L13.834 7.5"
        stroke="#48B16E"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip0_1682_1392)">
        <path
          d="M17.3342 11.9333C17.3342 12.2279 17.0955 12.4666 16.8009 12.4666C16.5064 12.4666 16.2676 12.2279 16.2676 11.9333C16.2676 11.8626 16.2813 11.7951 16.3063 11.7333C16.3855 11.5378 16.8009 11.1333 16.8009 11.1333C16.8009 11.1333 17.2164 11.5378 17.2955 11.7333C17.3205 11.7951 17.3342 11.8626 17.3342 11.9333Z"
          stroke="#474747"
          stroke-width="0.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1682_1392">
          <rect
            width="1.6"
            height="1.6"
            fill="white"
            transform="translate(16 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const PatientTransferred = ({ className, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2439 3.75L17.7561 3.75C17.6106 3.75 17.5378 3.75 17.4695 3.76034C17.1556 3.80788 16.8672 4.01669 16.6639 4.34364C16.6196 4.4148 16.5792 4.4967 16.4985 4.66052C16.3739 4.91333 16.3117 5.03973 16.2862 5.13629C16.1637 5.60013 16.3637 6.10574 16.7169 6.22514C16.7904 6.25 16.9027 6.25 17.1273 6.25L27.8727 6.25C28.0973 6.25 28.2096 6.25 28.2831 6.22514C28.6363 6.10574 28.8363 5.60013 28.7138 5.13629C28.6884 5.03974 28.626 4.9133 28.5015 4.66052C28.4208 4.49673 28.3804 4.41479 28.3361 4.34364C28.1328 4.01669 27.8444 3.80788 27.5305 3.76034C27.4622 3.75 27.3894 3.75 27.2439 3.75Z"
        stroke="#474747"
        stroke-linecap="round"
      />
      <path
        d="M19.6875 10.9375L26.875 6.25"
        stroke="#474747"
        stroke-linecap="round"
      />
      <path
        d="M25.3125 10.9375L18.125 6.25"
        stroke="#474747"
        stroke-linecap="round"
      />
      <path
        d="M20 11.875C20 12.5654 19.4404 13.125 18.75 13.125C18.0596 13.125 17.5 12.5654 17.5 11.875C17.5 11.1846 18.0596 10.625 18.75 10.625C19.4404 10.625 20 11.1846 20 11.875Z"
        stroke="#474747"
      />
      <path
        d="M27.5 11.875C27.5 12.5654 26.9404 13.125 26.25 13.125C25.5596 13.125 25 12.5654 25 11.875C25 11.1846 25.5596 10.625 26.25 10.625C26.9404 10.625 27.5 11.1846 27.5 11.875Z"
        stroke="#474747"
      />
      <path
        d="M18.75 3.75V2.56475C18.75 2.11624 19.1321 1.78714 19.5266 1.89592L20.7766 2.24063C21.0548 2.31735 21.25 2.59311 21.25 2.90945V3.75"
        stroke="#474747"
        stroke-linecap="round"
      />
      <line
        x1="7.38477"
        y1="8.75"
        x2="16.0002"
        y2="8.75"
        stroke="black"
        stroke-width="0.5"
      />
      <line
        x1="3.69141"
        y1="9.825"
        x2="14.7683"
        y2="9.825"
        stroke="black"
        stroke-width="0.35"
      />
      <line
        y1="10.825"
        x2="13.5385"
        y2="10.825"
        stroke="black"
        stroke-width="0.35"
      />
    </svg>
  );
};
