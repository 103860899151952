import React, { useEffect, useState } from "react";
import moment from "moment";
import { FaStethoscope } from "react-icons/fa";
import NewButton from "../../../components/NewButton";
import Modal from "../../../components/Modal";
import scissors from "../../../assets/Icons/scissors-cut-01.svg";
import hospital from "../../../assets/Icons/hospital.svg";
import equimentIcon from "../../../assets/Icons/scissorsCut.svg";
import pharmacyListIcon from "../../../assets/Icons/medicalDoc.svg";
import equipmentDark from "../../../assets/Icons/cutDark.svg";
import { PiSirenFill, PiUser } from "react-icons/pi";
import { LuDroplets } from "react-icons/lu";
import { PiSyringeLight } from "react-icons/pi";
import { PiVirusLight } from "react-icons/pi";
import { LuCalendarCheck2 } from "react-icons/lu";
import { LuClock9 } from "react-icons/lu";
import { PiSiren } from "react-icons/pi";
import { RiScissorsCutFill } from "react-icons/ri";
import { FaStore } from "react-icons/fa6";

import {
  BOOKING_TYPE,
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  LOG_TYPES,
  REQUEST_STATUS,
  SWC,
  WORDINGS,
} from "../../../helpers/constants";
import styles from "./index.module.css";
import { CssdKitList } from "../CssdKitList";
import {
  checkLogType,
  joinClass,
  joinStrings,
  removeUnderScore,
} from "../../../helpers/utils";
import {
  displayBookingStatus,
  SURGERY_STATUS_OPTIONS,
} from "../../../helpers/surgeryStatusOptions";
import {
  MdOutlineAddShoppingCart,
  MdOutlineCalendarToday,
  MdOutlineLocationOn,
} from "react-icons/md";

const RequestTab = ({ requestSurgeryDetails }) => {
  const [isEquipments, toggleIsEquipments] = useState(false),
    [isPharmacySupplies, toggleIsPharmacySupplies] = useState(false),
    [isStandardPharmacyList, toggleIsStandardPharmacyList] = useState(false),
    [isMaterialStoreList, toggleIsMaterialStoreList] = useState(false),
    [IsCssdKitList, toggleIsCssdKitList] = useState(false);

  return (
    <div className={styles["request-tab"]}>
      {isEquipments && (
        <Modal
          isOpen={isEquipments}
          onCancel={() => toggleIsEquipments(false)}
          title={
            <label className={styles["request-tab__modal-title"]}>
              {WORDINGS.EQUIPMENTS}
              <img src={equipmentDark} alt="equipments" />
            </label>
          }
          closeButton
        >
          <div className={styles["request-tab__equipments-modal"]}>
            <div className={styles["request-tab__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.EQUIPMENTS}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {Object.keys(requestSurgeryDetails?.equipment_request).length >
            0 ? (
              Object.values(requestSurgeryDetails?.equipment_request)?.map(
                (item, index) => (
                  <div
                    key={index}
                    className={styles["request-tab__equipments-list"]}
                  >
                    <div>{item?.name}</div>
                    <div>{item?.quantity}</div>
                  </div>
                )
              )
            ) : (
              <div className={styles["request-tab__no-item"]}>
                {WORDINGS.NO_EQUIPMENTS_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isPharmacySupplies && (
        <Modal
          isOpen={isPharmacySupplies}
          onCancel={() => toggleIsPharmacySupplies(false)}
          title={
            <label className={styles["request-tab__modal-title"]}>
              {WORDINGS.ADDITIONAL_ITEMS}
              <MdOutlineAddShoppingCart fontSize={22} />
            </label>
          }
          closeButton
        >
          <div className={styles["request-tab__pharmacy-modal"]}>
            <div className={styles["request-tab__pharmacy-list"]}>
              <div>{GLOBAL_CONSTANTS.VENDOR}</div>
              <div>{GLOBAL_CONSTANTS.ITEM}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {requestSurgeryDetails?.pharmacy_special_request.length > 0 ? (
              Object.values(
                requestSurgeryDetails?.pharmacy_special_request
              )?.map((item, index) => (
                <div
                  key={index}
                  className={styles["request-tab__pharmacy-list"]}
                >
                  <div>{item?.vendor}</div>
                  <div>{item?.item}</div>
                  <div>{item?.quantity}</div>
                </div>
              ))
            ) : (
              <div className={styles["request-tab__no-item"]}>
                {WORDINGS.NO_PHARMACY_SUPPLIES_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isStandardPharmacyList && (
        <Modal
          isOpen={isStandardPharmacyList}
          onCancel={() => toggleIsStandardPharmacyList(false)}
          title={
            <label className={styles["request-tab__modal-title"]}>
              {WORDINGS.STANDARD_PHARMACY_LIST}
            </label>
          }
          closeButton
        >
          <div className={styles["request-tab__equipments-modal"]}>
            <div className={styles["request-tab__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.NAME}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {requestSurgeryDetails?.pharmacy_standard_list.length > 0 ? (
              requestSurgeryDetails?.pharmacy_standard_list?.map(
                (item, index) => (
                  <div
                    key={index}
                    className={styles["request-tab__equipments-list"]}
                  >
                    <div>{item?.name}</div>
                    <div>{item?.quantity}</div>
                  </div>
                )
              )
            ) : (
              <div className={styles["request-tab__no-item"]}>
                {WORDINGS.NO_STANDARD_PHARMACY_LIST_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isMaterialStoreList && (
        <Modal
          isOpen={isMaterialStoreList}
          onCancel={() => toggleIsMaterialStoreList(false)}
          title={
            <label className={styles["request-tab__modal-title"]}>
              {WORDINGS.MATERIAL_STORE_LIST}
            </label>
          }
          closeButton
        >
          <div className={styles["request-tab__equipments-modal"]}>
            <div className={styles["request-tab__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.NAME}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {requestSurgeryDetails?.standard_material_list?.length > 0 ? (
              requestSurgeryDetails?.standard_material_list?.map(
                (item, index) => (
                  <div
                    key={index}
                    className={styles["request-tab__equipments-list"]}
                  >
                    <div>{item?.name}</div>
                    <div>{item?.quantity}</div>
                  </div>
                )
              )
            ) : (
              <div className={styles["request-tab__no-item"]}>
                {WORDINGS.NO_STANDARD_MATERIAL_LIST_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {IsCssdKitList && (
        <Modal
          isOpen={IsCssdKitList}
          onCancel={() => toggleIsCssdKitList(false)}
          title={
            <label className={styles["request-tab__modal-title"]}>
              {WORDINGS.CSSD_KIT_LIST}
            </label>
          }
          closeButton
        >
          <CssdKitList data={requestSurgeryDetails} />
        </Modal>
      )}
      <div className={styles["warning-status-container"]}>
        <p>{requestSurgeryDetails?.request_number}</p>
        {requestSurgeryDetails?.surgery_classification ===
          BOOKING_TYPE.EMERGENCY && (
          <PiSirenFill fontSize={30} color="#B71F1F" />
        )}
        <label
          className={
            requestSurgeryDetails?.request_status ===
              REQUEST_STATUS.SCHEDULED && styles["green-label"]
          }
        >
          {displayBookingStatus[requestSurgeryDetails?.request_status]}
        </label>
      </div>
      <div className={styles["calender-loc-container"]}>
        <div className={styles["calendar"]}>
          <span className={styles["icon-container"]}>
            <MdOutlineCalendarToday fontSize={20} color="#155697" />
          </span>
          {moment(requestSurgeryDetails?.surgery_start_time).format(
            DATE_TIME_FORMAT.Do_MMMM_YYYY
          )}
        </div>
        <div className={styles["location"]}>
          <span className={styles["icon-container"]}>
            <MdOutlineLocationOn fontSize={20} color="#155697" />
          </span>
          <p>
            {requestSurgeryDetails?.requested_ot?.name} (
            {requestSurgeryDetails?.booking_type === BOOKING_TYPE.NORMAL
              ? WORDINGS.LISTED_HOURS
              : WORDINGS.NON_LISTED_HOURS}
            )
          </p>
        </div>
      </div>
      <div
        className={joinClass(
          styles["patient-details-container"],
          requestSurgeryDetails?.swc?.name === SWC.CLEAN
            ? styles["box-shadow-clean"]
            : styles["box-shadow-non-clean"]
        )}
      >
        <div className={styles["patient-details-container-header"]}>
          <div className={styles["patient-details-avatar"]}>
            <PiUser fontSize={38} color="#155697" />
          </div>
          <div className={styles["patient-details"]}>
            {requestSurgeryDetails?.patient_name && (
              <p>
                {requestSurgeryDetails?.patient_name}
                {requestSurgeryDetails?.age && (
                  <span> ( {requestSurgeryDetails?.age} )</span>
                )}
              </p>
            )}
            {requestSurgeryDetails?.patient_mrn && (
              <p>
                {`${WORDINGS.MR_NUMBER}: ${requestSurgeryDetails?.patient_mrn}`}
              </p>
            )}
            {requestSurgeryDetails?.uhid && (
              <p>{`${WORDINGS.UHID}: ${requestSurgeryDetails?.uhid}`}</p>
            )}
          </div>
        </div>
        <div className={styles["patient-details-container-footer"]}>
          {requestSurgeryDetails?.swc && (
            <label
              className={
                requestSurgeryDetails?.swc.name === SWC.CLEAN
                  ? styles["skyblue-label"]
                  : styles["red-label"]
              }
            >
              {requestSurgeryDetails?.swc.name}
            </label>
          )}
          {checkLogType(
            requestSurgeryDetails?.booking_confirmation_logs,
            LOG_TYPES.PAC
          ) && <label>{WORDINGS.PAC_REQUIRED}</label>}
        </div>
      </div>

      {checkLogType(
        requestSurgeryDetails?.booking_confirmation_logs,
        LOG_TYPES.BLOOD_BANK
      ) && (
        <label className={styles["request-tab_day"]}>
          <LuDroplets fontSize={24} color="#B71F1F" />
          {WORDINGS.BLOOD_BANK_READINESS}:<span>{WORDINGS.REQUIRED}</span>
        </label>
      )}
      {requestSurgeryDetails?.type_of_anesthesia && (
        <label className={styles["request-tab_day"]}>
          <PiSyringeLight fontSize={24} />
          {WORDINGS.ANAESTHESIA}:
          <span>{joinStrings(requestSurgeryDetails?.type_of_anesthesia)}</span>
        </label>
      )}

      {requestSurgeryDetails?.pre_existing_infections && (
        <label className={styles["request-tab_day"]}>
          <PiVirusLight fontSize={24} />
          {WORDINGS.PRE_EXISTING_INFECTIONS}:
          <span>
            {requestSurgeryDetails?.pre_existing_infections.name === "Others"
              ? requestSurgeryDetails?.pre_existing_infections.notes
              : requestSurgeryDetails?.pre_existing_infections.name}
          </span>
        </label>
      )}

      <div className={styles["request-tab__detail"]}>
        <section>
          <label>{WORDINGS.SURGERY_DETAILS}</label>
          <div className={styles["request-tab__details"]}>
            <div>
              <LuCalendarCheck2 size={20} color="#0E376E" />
              <label className={styles["font-red"]}>
                {`${removeUnderScore(
                  requestSurgeryDetails?.booking_mode
                )},${removeUnderScore(
                  requestSurgeryDetails?.surgery_classification
                )},${removeUnderScore(requestSurgeryDetails?.surgery_type)} ${
                  GLOBAL_CONSTANTS.SURGERY
                }`}
              </label>
            </div>
            <div>
              <img src={hospital} alt="Speciality" />
              <label>{requestSurgeryDetails?.requested_department?.name}</label>
            </div>
            <div
              className={
                requestSurgeryDetails?.surgery_types?.length > 1
                  ? styles["request-tab__surgery-name"]
                  : ""
              }
            >
              <img src={scissors} alt="surgery" />
              {requestSurgeryDetails?.surgery_types?.length > 1 ? (
                <label>
                  {requestSurgeryDetails?.surgery_types?.map(
                    (surgery, index) => (
                      <div key={index}>
                        {index + 1}. {surgery.name}
                      </div>
                    )
                  )}
                </label>
              ) : (
                <label>
                  {requestSurgeryDetails?.surgery_types?.map(
                    (surgery, index) => (
                      <div key={index}>{surgery.name}</div>
                    )
                  )}
                </label>
              )}
            </div>
            <div>
              <FaStethoscope size={20} color="#0E376E" />
              <label>
                {
                  requestSurgeryDetails?.crew_data_details?.chief_surgeon[0]
                    ?.name
                }
              </label>
            </div>
          </div>
        </section>
        <section>
          <label>{WORDINGS.TIME_SLOT}</label>
          <div className={styles["request-tab__slot-details"]}>
            <div className={styles["clock-box"]}>
              <LuClock9 fontSize={20} color="#155697" />
              {`${moment(requestSurgeryDetails?.start).format(
                DATE_TIME_FORMAT.hh_mm_A
              )} - ${moment(requestSurgeryDetails?.end).format(
                DATE_TIME_FORMAT.hh_mm_A
              )}`}
            </div>
            <div className={styles["time-slot-slots"]}>
              <label>{`${GLOBAL_CONSTANTS.PROCEDURE_TIME}: ${
                (requestSurgeryDetails?.surgery_end_time -
                  requestSurgeryDetails?.surgery_start_time) /
                60000
              } ${GLOBAL_CONSTANTS.MINS}`}</label>
              <label>{`${GLOBAL_CONSTANTS.CLEANING_TIME}: ${
                requestSurgeryDetails?.buffer_time / 60000
              } ${GLOBAL_CONSTANTS.MINS}`}</label>
            </div>
          </div>
        </section>
        {requestSurgeryDetails?.notes && (
          <section>
            <label>{WORDINGS.NOTES}</label>
            <div className={styles["request-tab__slot-details"]}>
              {requestSurgeryDetails?.notes}
            </div>
          </section>
        )}
        <div className={styles["request-tab__detail-bottom-container"]}>
          <label>{WORDINGS.OTHER_DETAILS}</label>
          <div
            className={styles["request-tab__detail-bottom-container-buttons"]}
          >
            <NewButton modalButton onClick={() => toggleIsEquipments(true)}>
              {
                <div className={styles["request-tab__modal-buttons"]}>
                  <img src={equimentIcon} alt="Equiments_icon" />
                  <label>{WORDINGS.EQUIPMENTS}</label>
                </div>
              }
            </NewButton>
            <NewButton
              modalButton
              onClick={() => toggleIsPharmacySupplies(true)}
            >
              {
                <div className={styles["request-tab__modal-buttons"]}>
                  <MdOutlineAddShoppingCart fontSize={22} />
                  <label>{WORDINGS.ADDITIONAL_ITEMS}</label>
                </div>
              }
            </NewButton>
            <NewButton
              modalButton
              onClick={() => toggleIsStandardPharmacyList(true)}
            >
              {
                <div className={styles["request-tab__modal-buttons"]}>
                  <img src={pharmacyListIcon} alt="Equiments_icon" />
                  <label>{WORDINGS.STANDARD_PHARMACY_LIST}</label>
                </div>
              }
            </NewButton>
            <NewButton
              modalButton
              onClick={() => toggleIsMaterialStoreList(true)}
            >
              {
                <div className={styles["request-tab__modal-buttons"]}>
                  <FaStore fontSize={24} />
                  <label>{WORDINGS.MATERIAL_STORE_LIST}</label>
                </div>
              }
            </NewButton>
            <NewButton modalButton onClick={() => toggleIsCssdKitList(true)}>
              {
                <div className={styles["request-tab__modal-buttons"]}>
                  <RiScissorsCutFill fontSize={24} />
                  <label>{WORDINGS.CSSD_KIT_LIST}</label>
                </div>
              }
            </NewButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestTab;
