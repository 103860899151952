import React, { useEffect, useMemo, useState } from "react";
import {
  getItemFromLocalStorage,
  handleAlertError,
  joinClass,
} from "../../../../helpers/utils";
import {
  ALL_REGEX,
  BED_STATUS,
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  REQUEST_STATUS,
  WORDINGS,
} from "../../../../helpers/constants";
import AdvancedDropdown from "../../../../components/AdvancedDropdown";
import styles from "./index.module.css";
import { SURGERY_STATUS_OPTIONS } from "../../../../helpers/surgeryStatusOptions";
import NewButton from "../../../../components/NewButton";
import TextInput from "../../../../components/TextInput";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import {
  getBeds,
  getPostOts,
  updateOtStatus,
} from "../../../MySchedules/actions/myScheduleActions";
import moment from "moment";

export const StatusUpdateModal = ({
  label,
  scheduleId,
  otId,
  onCancel,
  isSearchable,
}) => {
  const dispatch = useDispatch();

  const { scheduleDetails = {} } = useSelector(
    (state) => state.myScheduleReducer
  );
  const { bedList = {} } = useSelector((state) => state.myScheduleReducer);
  const { postOtAreas = {} } = useSelector((state) => state.myScheduleReducer);

  const { units } = getItemFromLocalStorage("user") || {};

  const { is_crew_member: isCrewMember, privileges = {} } =
    getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};

  const [surgeryStatus, setSurgeryStatus] = useState(null);
  const [hour, setHour] = useState(null);
  const [loader, setLoader] = useState(false);
  const [bedLoader, setBedLoader] = useState(false);
  const [area, setArea] = useState(null);
  const [bed, setBed] = useState(null);
  const [postOtAreaLoader, setPostOtAreaLoader] = useState(false);

  const handleHourChange = (value) => {
    if (value === "") {
      setHour(null);
      return;
    }

    if (ALL_REGEX.ALLOW_THREE_DIGIT_REGEX.test(value)) {
      setHour(Number(value));
    }
  };

  const getOptions = () => {
    const excludedOptions = scheduleDetails?.logs?.map(
      (option) => option.log_type
    );
    return SURGERY_STATUS_OPTIONS?.filter(
      (option) =>
        privileges[option?.privilege] === true &&
        !excludedOptions?.includes(option.value)
    );
  };

  const handleStatusUpdate = () => {
    if (surgeryStatus) {
      const body = {
        note: "",
        bedId: bed || "",
        operativeAreaId: otId || "",
        expected_evacuation: hour ? moment().add(hour, "hours").unix() : "",
        patient_name: scheduleDetails?.patient_name || "",
        surgeon: scheduleDetails?.surgeon || "",
      };
      setLoader(true);
      dispatch(
        updateOtStatus(
          units[0].id,
          scheduleId,
          surgeryStatus.value,
          body,
          setLoader,
          onCancel
        )
      );
    }
  };

  const handleSingleButtonClick = (option) => {
    if (option) {
      surgeryStatus ? setSurgeryStatus("") : setSurgeryStatus(option);
    }
  };

  useEffect(() => {
    dispatch(getPostOts(units[0].id, otId, setPostOtAreaLoader));
    setSurgeryStatus(null);
  }, []);

  useEffect(() => {
    const params = {
      operativeArea: area || "",
      status: BED_STATUS.UNOCCUPIED,
    };
    dispatch(getBeds(units[0].id, params, setBedLoader));
    if (area && !bedList.length > 0)
      dispatch(handleAlertError(WORDINGS.NO_BEDS_AVAILABLE_AT_THE_MOMENT));
  }, [area]);

  const isDisabled = useMemo(() => {
    if (!surgeryStatus) return true;
    // return (
    //   surgeryStatus.value === REQUEST_STATUS.WHEEL_OUT &&
    //   (!area || !bed || !hour)
    // );
  }, [surgeryStatus, area, bed, hour]);

  return (
    <div className={styles["statusUpdateModal"]}>
      {loader ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={styles["statusUpdateModal-div"]}>
          {getOptions().length > 1 ? (
            <AdvancedDropdown
              label={WORDINGS.STATUS}
              placeholder={GLOBAL_MESSAGES.SELECT}
              options={getOptions()}
              onChange={setSurgeryStatus}
              value={surgeryStatus}
              isSearchable={isSearchable}
            />
          ) : (
            getOptions()?.map((option) => (
              <NewButton
                labelButton
                className={joinClass(
                  styles["statusUpdateModal__singleBtn"],
                  surgeryStatus &&
                    styles["statusUpdateModal__singleBtn__selected"]
                )}
                onClick={() => handleSingleButtonClick(option)}
              >
                <span className={styles["statusUpdateModal__singleBtn__label"]}>
                  <label> {option.label}</label>
                </span>
              </NewButton>
            ))
          )}
          {surgeryStatus?.value === REQUEST_STATUS.WHEEL_OUT && (
            <div className={styles["statusUpdateModal__conditional-fields"]}>
              <div
                className={
                  styles["statusUpdateModal__conditional-fields-wheelOut"]
                }
              >
                <AdvancedDropdown
                  options={postOtAreas}
                  placeholder={GLOBAL_MESSAGES.SELECT}
                  label={WORDINGS.SELECT_AREA_OF_TRANSFER}
                  onChange={(value) => setArea(value.id)}
                  disabled={postOtAreas?.length < 0}
                />
                {postOtAreas?.length < 0 && (
                  <label>{WORDINGS.NO_POST_OT_AREAS}</label>
                )}
              </div>
              {area && (
                <div
                  className={
                    styles["statusUpdateModal__conditional-fields-wheelOut"]
                  }
                >
                  <AdvancedDropdown
                    options={bedList}
                    placeholder={GLOBAL_MESSAGES.SELECT}
                    label={WORDINGS.SELECT_BED}
                    onChange={(value) => setBed(value.id)}
                    disabled={bedList?.length < 0}
                  />
                  {bedList?.length < 0 && (
                    <label>{WORDINGS.NO_BEDS_AVAILABLE}</label>
                  )}
                </div>
              )}
              {bed && (
                <div className={styles["statusUpdateModal__hours-container"]}>
                  <label>{WORDINGS.DURATION_OF_STAY}</label>
                  <div className={styles["statusUpdateModal__hours"]}>
                    <TextInput
                      type="text"
                      value={hour}
                      onChange={handleHourChange}
                      className={styles["statusUpdateModal__input"]}
                    />
                    <label className={styles["statusUpdateModal__label"]}>
                      {WORDINGS.HOURS}
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
          <NewButton
            largeButton
            className={styles["statusUpdateModal__add-btn"]}
            onClick={handleStatusUpdate}
            disabled={isDisabled}
          >
            <label>{WORDINGS.UPDATE}</label>
          </NewButton>
        </div>
      )}
    </div>
  );
};
