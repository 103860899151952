import { GLOBAL_CONSTANTS, WORDINGS } from "../../../helpers/constants";
import requestMethods from "../../../helpers/request";
import {
  getItemFromLocalStorage,
  handleAlertError,
  handleAlertSuccess,
} from "../../../helpers/utils";
import { STATUS_UPDATE_CONSTANTS } from "../constants";

export const getSchedules =
  (unitId, setLoader, params, operativeArea, type, timeline) =>
  async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};

    try {
      const res = await requestMethods.GET({
        endpoint: `v1/ot/schedule/web/${companyId}/${unitId}/pwa-with-privilege-get-all/${operativeArea}/${type}/${timeline}`,
        params,
      });
      if (res.status) {
        dispatch({
          type: STATUS_UPDATE_CONSTANTS.GET_OT_SUCCESS,
          payload: res.schedules,
          totalCount: res.total_count,
        });
        if (setLoader) setLoader(false);
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));

      if (setLoader) setLoader(false);
    }
  };

export const updateOtStatus =
  (
    unitId,
    scheduleId,
    onCancel,
    status,
    params,
    operativeAreaId,
    type,
    timeFIlter,
    setLoader
  ) =>
  async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};
    try {
      const res = await requestMethods.PUT({
        endpoint: `v1/ot/schedule/web/${companyId}/${unitId}/update-schedule-status/${scheduleId}/${status}`,
        body: {
          note: "",
        },
      });
      if (res.status) {
        dispatch(
          getSchedules(
            unitId,
            setLoader,
            params,
            operativeAreaId,
            type,
            timeFIlter
          )
        );
        if (setLoader) setLoader(false);
        onCancel();
        dispatch(handleAlertSuccess(WORDINGS.STATUS_UPDATED_SUCCESSFULLY));
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (setLoader) setLoader(false);
    }
  };

export const getAreas = (unitId, type, setLoader) => async (dispatch) => {
  const { company: { id: companyId = "" } = {} } =
    getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};

  try {
    const res = await requestMethods.GET({
      endpoint: `v1/ot/operative-area/web/${companyId}/${unitId}/get-operative-areas`,
      params: {
        operativeAreaType: type,
      },
    });
    if (res.status) {
      dispatch({
        type: STATUS_UPDATE_CONSTANTS.GET_OPERATIVE_AREA_SUCCESS,
        payload: res.ot_operative_areas,
      });
      if (setLoader) setLoader(false);
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));

    if (setLoader) setLoader(false);
  }
};
