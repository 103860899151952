import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CgTrash } from "react-icons/cg";
import moment from "moment";
import {
  BUTTON_TYPES,
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  SURGERY_CLASSIFICATION,
  WORDINGS,
} from "../../../helpers/constants";
import {
  getDepartments,
  getEquipmentModels,
  getSurgeons,
  getSurgeryTypes,
  resetBookingAvailabilityOverview,
} from "../../BookingOverview/actions/bookingActions";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import NewButton from "../../../components/NewButton";
import iconCalendar from "../../../assets/Icons/iconCalendar.png";
import Modal from "../../../components/Modal";
import NewCalender from "../../../components/NewCalendar";
import {
  checkObjectNotEmpty,
  checkTimeDifference,
  getItemFromLocalStorage,
  getMomentTimeDate,
  handleAlertError,
} from "../../../helpers/utils";
import NewModal from "../../../components/NewModal";

import NewTimeDropdown from "../../../components/NewTimeDropdown";
import styles from "./index.module.css";
import RadioButton from "../../../components/RadioButton";
import { INPUT_ACTIONS } from "../constants";
import { setConfirmationLogs, setSpecialityInfo } from "../action";
import EquipmentModal from "./EquipmentModal";
import { permissions } from "../../../helpers/permission";

const SurgeryDetails = (props) => {
  const { closureTime, setClosureTime, setStep } = props;
  const dispatch = useDispatch();
  const {
    units = [],
    department,
    crew_details: chiefSurgeon,
    privileges = {},
    designation,
  } = getItemFromLocalStorage("user") || {};

  const {
    surgeryDate: selectedSurgeryDate,
    surgeryDetails: {
      startTime = null,
      endTime = 0,
      procedureTime: prevProcedureTime = 0,
      bufferTime: prevBufferTime = 0,
      crewDetails: prevCrewDetails = null,
      equipmentModels: prevEquimentModels = {},
      surgeryList = [],
      surgeryClassification: prevSurgeryClassification = "elective",
    } = {},
  } = useSelector((state) => state.quickInputReducer);

  const {
    departments,
    surgeryTypes,
    surgeons,
    equipmentModels: eqModels,
  } = useSelector((state) => state.bookingReducer);

  const [equipmentModels, setEquipmentModels] = useState(prevEquimentModels);
  const [surgeryName, setSurgeryName] = useState(null),
    [tempCheckDate, setTempCheckDate] = useState(false),
    [tempCheckToday, setTempCheckToday] = useState(false),
    [tempBookingMode, setTempBookingMode] = useState(false),
    [selectDate, setSelectDate] = useState(moment(selectedSurgeryDate)),
    [equipmentModel, setEquipmentModel] = useState(null),
    [equipmentQty, setEquipmentQty] = useState("1"),
    [deleteSurgery, setDeleteSurgery] = useState(),
    [bookingConfirmations, setBookingConfirmations] = useState([]),
    [setDate, toggleSetDate] = useState(false);

  const [surgeryNames, setSurgeryNames] = useState(surgeryList);
  const [surgeryStartTime, setSurgeryStartTime] = useState(startTime);
  const [surgeryEndTime, setSurgeryEndTime] = useState(endTime);
  const [procedureTime, setProcedureTime] = useState(prevProcedureTime);
  const [bufferTime, setBufferTime] = useState(prevBufferTime);
  const [crewDetails, setCrewDetails] = useState(prevCrewDetails);

  const [openEquipment, toggleEquipment] = useState(false);
  const [isEquipmentModelDelete, toggleEquipmentModelDelete] = useState(false);
  const [isEquipmentModelUpdate, toggleEquipmentModelUpdate] = useState(false);
  const [selectedEquipmentModel, setSelectedEquipmentModel] = useState(null);
  const [surgeryClassification, setSurgeryClassification] = useState(
    prevSurgeryClassification
  );

  const [speciality, setSpeciality] = useState(null);
  const [surgeonName, setSurgeonName] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleDeleteSurgery = (surgery) => {
    setDeleteSurgery(surgery);
  };

  const handleDeleteModel = () => {
    const data = {};

    Object.values(equipmentModels).forEach((item) => {
      if (selectedEquipmentModel.id !== item?.id) {
        data[item.id] = { ...item };
      }
    });
    setEquipmentModels({ ...data });
    toggleEquipmentModelDelete(false);
  };

  const handleEquipment = () => {
    if (surgeryNames?.length > 0) {
      toggleEquipment(true);
    } else {
      dispatch(handleAlertError(GLOBAL_MESSAGES.SELECT_SURGERY_FIRST));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetBookingAvailabilityOverview());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getSurgeryTypes(units[0]?.id, {
        ignorePaging: true,
        departmentId: department?.id,
      })
    );
    dispatch(getSurgeons(units[0]?.id), department?.id);
    dispatch(getEquipmentModels(units[0]?.id, { ignorePaging: true }));
    dispatch(getDepartments({ ignorePaging: true }, setLoader));
  }, []);

  useEffect(() => {
    if (surgeryName) {
      setSurgeryNames([...surgeryNames, surgeryName]);
      setSurgeryName(null);
    }
  }, [surgeryName]);

  useEffect(() => {
    dispatch(getSurgeons(units[0]?.id, speciality?.id));
  }, [speciality]);

  useEffect(() => {
    if (surgeryNames?.length > 0) {
      let equipmentList = { ...equipmentModels };
      let addedProcedureTime = 0;
      const bufferTimes = [];
      let crewData = {};
      const confirmationLogs = new Set();

      for (const surgery of surgeryNames) {
        equipmentList = {
          ...equipmentList,
          ...surgery?.associated_equipment_models,
        };
      }
      surgeryNames.forEach((surgery) => {
        bufferTimes.push(surgery?.buffer_time_required);
        addedProcedureTime =
          addedProcedureTime + surgery?.standard_procedure_time;
        crewData = { ...crewData, ...surgery?.crew_type?.crew_details };

        if (surgery?.booking_notifications?.length > 0) {
          surgery?.booking_notifications.forEach((notification) =>
            confirmationLogs.add(notification)
          );
        }
      });

      setCrewDetails(crewData);
      setProcedureTime(addedProcedureTime);
      setBufferTime(Math.max(...bufferTimes));
      setEquipmentModels(equipmentList);
      setBookingConfirmations(Array.from(confirmationLogs));
    }
  }, [surgeryNames]);

  useEffect(() => {
    setTempCheckDate(selectDate.isAfter(moment().add(1, DATE_TIME_FORMAT.DAY)));
    setTempCheckToday(selectDate.isSame(moment(), DATE_TIME_FORMAT.DAY));
    setClosureTime(checkTimeDifference(units[0]?.ot_booking_closure_time));
    setTempBookingMode((!tempCheckDate && !closureTime) || tempCheckToday);
  }, [selectDate, tempCheckDate, closureTime, tempCheckToday]);

  useEffect(() => {
    if (equipmentModel) {
      if (equipmentModels[equipmentModel?.id]) {
        setEquipmentModels({
          ...equipmentModels,
          [equipmentModel?.id]: {
            ...equipmentModel,
            quantity:
              +equipmentQty + equipmentModels[equipmentModel?.id]?.quantity,
          },
        });
      } else {
        setEquipmentModels({
          ...equipmentModels,
          [equipmentModel?.id]: {
            ...equipmentModel,
            quantity: +equipmentQty,
          },
        });
      }

      setEquipmentModel(null);
    }
  }, [equipmentModel]);

  useEffect(() => {
    if (surgeryStartTime) {
      setSurgeryStartTime(
        getMomentTimeDate(moment(surgeryStartTime), moment(selectedSurgeryDate))
      );
    }
  }, [selectedSurgeryDate]);

  const handleSubmitProceed = () => {
    if (designation !== GLOBAL_CONSTANTS.SURGEON) {
      dispatch(setSpecialityInfo({ chiefSurgeon: surgeonName, speciality }));
    }
    if (validateAllFields()) {
      dispatch(handleAlertError(GLOBAL_MESSAGES.FILL_REQUIRED_FIELDS));
    } else {
      const pharmacyNotes = surgeryNames[0]?.pharmacy_notes?.trim() || null;
      const data = {
        startTime: surgeryStartTime,
        endTime: surgeryStartTime + procedureTime + bufferTime,
        procedureTime,
        bufferTime,
        bookingMode: tempBookingMode,
        crewDetails,
        equipmentModels,
        pharmacyNotes,
        surgeryList: surgeryNames,
        surgeryClassification,
      };
      dispatch({
        type: INPUT_ACTIONS.SET_SURGERY_DETAILS,
        payload: data,
      });
      dispatch(setConfirmationLogs(bookingConfirmations));
      setSurgeryEndTime(surgeryStartTime + procedureTime + bufferTime);
      setStep(2);
    }
  };

  const classificationList = [
    {
      name: SURGERY_CLASSIFICATION.ELECTIVE,
      label: WORDINGS.ELECTIVE,
    },
    {
      name: SURGERY_CLASSIFICATION.URGENT,
      label: WORDINGS.URGENT,
    },
    {
      name: SURGERY_CLASSIFICATION.EMERGENCY,
      label: WORDINGS.EMERGENCY,
    },
  ];

  const handleSetDate = () => {
    dispatch({
      type: INPUT_ACTIONS.SET_SURGERY_DATE,
      payload: +moment(selectDate),
    });
    toggleSetDate(false);
  };

  const validateAllFields = () => {
    if (designation !== GLOBAL_CONSTANTS.SURGEON) {
      return (
        surgeryNames?.length < 1 ||
        surgeryStartTime < +moment() ||
        !surgeonName ||
        !speciality
      );
    } else {
      return surgeryNames?.length < 1 || surgeryStartTime < +moment();
    }
  };

  return (
    <div className={styles["surgery-details"]}>
      {deleteSurgery && (
        <NewModal
          isOpen={deleteSurgery}
          onCancel={() => setDeleteSurgery(null)}
        >
          <div className={styles["surgery-details__delete-surgery-modal"]}>
            <label>{`${GLOBAL_MESSAGES.REMOVE} "${deleteSurgery?.name}" from list ?`}</label>
            <div
              className={
                styles["surgery-details__delete-surgery-modal__button"]
              }
            >
              <NewButton
                shadow
                onClick={() => {
                  setSurgeryNames(
                    surgeryNames?.filter(
                      (data) => data.id !== deleteSurgery?.id
                    )
                  );
                  setDeleteSurgery(false);
                }}
              >
                {BUTTON_TYPES.REMOVE}
              </NewButton>
              <NewButton
                cancelButton
                shadow
                onClick={() => setDeleteSurgery(false)}
              >
                {BUTTON_TYPES.CANCEL}
              </NewButton>
            </div>
          </div>
        </NewModal>
      )}
      {setDate && (
        <Modal
          isOpen={setDate}
          onCancel={() => {
            toggleSetDate(false);
            setSelectDate(moment(selectedSurgeryDate));
          }}
          fullModal
          closeButton
        >
          <div className={styles["surgery-details__modal__select-date"]}>
            <h2>{WORDINGS.SELECT_SURGERY_DATE}</h2>
            <label>
              {`${WORDINGS.SURGERY_DATE} : 
              ${moment(selectDate).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}`}
            </label>
            <NewCalender value={selectDate} setValue={setSelectDate} />
            <span
              className={
                styles["surgery-details__modal__select-date_booking-mode"]
              }
            >
              {tempBookingMode && WORDINGS.UNPLANNED_ALERT}
            </span>
            <div className={styles["surgery-details__modal-button"]}>
              <NewButton
                className={styles["surgery-details__modal-button"]}
                largeButton
                onClick={handleSetDate}
              >
                {WORDINGS.DONE}
              </NewButton>
            </div>
          </div>
        </Modal>
      )}
      <section>
        <h2 className={styles["surgery-details__title"]}>
          {GLOBAL_MESSAGES.ENTER_SURGERY_DETAILS}
        </h2>
      </section>
      <section className={styles["surgery-details__dropdowns-container"]}>
        {designation !== GLOBAL_CONSTANTS.SURGEON && (
          <div className={styles["surgery-details__dropdowns-privileged"]}>
            <AdvancedDropdown
              label={WORDINGS.SPECIALITY}
              required={surgeryNames?.length === 0}
              value={speciality}
              onChange={(value) => setSpeciality(value)}
              options={departments}
              placeholder={GLOBAL_MESSAGES.SELECT}
            />
            <AdvancedDropdown
              label={WORDINGS.CHIEF_SURGEON}
              required={surgeryNames?.length === 0}
              value={surgeonName}
              onChange={(value) => setSurgeonName(value)}
              options={surgeons}
              placeholder={GLOBAL_MESSAGES.SELECT}
            />
          </div>
        )}
        <NewTimeDropdown
          title={GLOBAL_CONSTANTS.START_TIME}
          selectedTime={surgeryStartTime}
          setSelectedTime={setSurgeryStartTime}
          selectedDate={moment(selectedSurgeryDate)}
        />
        <div className={styles["surgery-details__surgery-names"]}>
          <AdvancedDropdown
            label="Surgery Name"
            required={surgeryNames?.length === 0}
            value={surgeryName}
            onChange={(value) => setSurgeryName(value)}
            options={surgeryTypes?.filter(
              (surgery) =>
                !surgeryNames?.find(
                  (surgeryType) => surgeryType?.id === surgery?.id
                )
            )}
            placeholder={GLOBAL_MESSAGES.SELECT}
          />
        </div>
        {surgeryNames?.length === 0 && <div></div>}
        {surgeryNames?.length > 0 && (
          <div className={styles["surgery-details__surgeryNames"]}>
            {surgeryNames.map((surgery) => (
              <div
                key={surgery?.id}
                className={styles["surgery-details__surgeryName"]}
              >
                <div className={styles["surgery-details__surgery-detail"]}>
                  <div>{surgery?.name}</div>
                  <div>
                    <span>{`${GLOBAL_CONSTANTS.SURGERY_DURATION}: ${
                      surgery?.standard_procedure_time / 60000
                    } ${GLOBAL_CONSTANTS.MINS}`}</span>
                    <span>{`${GLOBAL_CONSTANTS.CLEANING_DURATION}: ${
                      surgery?.buffer_time_required / 60000
                    } ${GLOBAL_CONSTANTS.MINS}`}</span>
                  </div>
                </div>
                <div
                  onClick={() => handleDeleteSurgery(surgery)}
                  className={styles["surgery-details__delete-btn"]}
                >
                  <CgTrash color="#B51616" fontSize={24} />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={styles["surgery-details__classification"]}>
          <label>{WORDINGS.HOW_WOULD_YOU_CLASSIFY_SURGERY}</label>
          {classificationList?.map((item, index) => (
            <RadioButton
              key={index}
              label={item?.label}
              name={item?.name}
              value={surgeryClassification}
              onChange={(value) => setSurgeryClassification(value)}
              className={styles["surgery-details__classification-radio"]}
            />
          ))}
        </div>
        <div className={styles["surgery-details__button-group"]}>
          <NewButton popupButton shadow onClick={() => toggleSetDate(true)}>
            <label className={styles["surgery-details__button-label"]}>
              {moment(selectedSurgeryDate).format(DATE_TIME_FORMAT.DD_MMM_YYYY)}
              <img src={iconCalendar} alt="icon-calendar" />
            </label>
          </NewButton>
          <NewButton popupButton shadow onClick={handleEquipment}>
            <label className={styles["surgery-details__button-label"]}>
              {GLOBAL_CONSTANTS.EQUIPMENTS}
              {checkObjectNotEmpty(equipmentModels) &&
                ` (${Object.keys(equipmentModels)?.length})`}
            </label>
          </NewButton>
        </div>
        {surgeryNames?.length === 0 && <div></div>}
        <NewButton largeButton onClick={handleSubmitProceed}>
          {BUTTON_TYPES.FIND_AVAILABLE_OT}
        </NewButton>
      </section>
      {openEquipment && (
        <Modal
          isOpen={openEquipment}
          fullModal
          closeButton
          onCancel={() => {
            toggleEquipment(false);
            setEquipmentModels(equipmentModels);
          }}
          title={WORDINGS.SELECT_EQUIPMENTS}
        >
          <EquipmentModal
            eqModels={eqModels}
            setEquipmentModels={setEquipmentModels}
            equipmentModels={equipmentModels}
            toggleEquipmentModelUpdate={toggleEquipmentModelUpdate}
            setSelectedEquipmentModel={setSelectedEquipmentModel}
            toggleEquipmentModelDelete={toggleEquipmentModelDelete}
            isEquipmentModelUpdate={isEquipmentModelUpdate}
            selectedEquipmentModel={selectedEquipmentModel}
            isEquipmentModelDelete={isEquipmentModelDelete}
            toggleEquipment={toggleEquipment}
          />
        </Modal>
      )}
    </div>
  );
};

export default SurgeryDetails;
