import React from "react";
import styles from "./index.module.css";
import { GLOBAL_CONSTANTS, WORDINGS } from "../../../../helpers/constants";

export const CssdKitList = ({ data }) => {
  return (
    <div className={styles["schedule-tab__cssd-modal"]}>
      <div className={styles["schedule-tab__cssd-list"]}>
        <div>{GLOBAL_CONSTANTS.NAME}</div>
        <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
      </div>
      {data?.cssd_kit_list.length > 0 ? (
        data?.cssd_kit_list?.map((item, index) => (
          <div key={index} className={styles["schedule-tab__cssd-list"]}>
            <div>{item?.kit_name}</div>
            <div>{item?.quantity}</div>
          </div>
        ))
      ) : (
        <div className={styles["schedule-tab__no-item"]}>
          {WORDINGS.NO_CSSD_KIT_FOUND}
        </div>
      )}
    </div>
  );
};
