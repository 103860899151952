import { GLOBAL_CONSTANTS, WORDINGS } from "../../../helpers/constants";
import requestMethods from "../../../helpers/request";
import {
  getItemFromLocalStorage,
  handleAlertError,
  handleAlertSuccess,
} from "../../../helpers/utils";
import { getRequestDetails } from "../../BookingOverview/actions/bookingActions";
import { MY_SCHEDULE_ACTIONS } from "./constants";

export const getMySchedules =
  (
    unitId,
    otId,
    surgeryTypeId,
    timestamp,
    page,
    size,
    sort,
    ignorePaging,
    loader
  ) =>
  async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};
    try {
      const res = await requestMethods.GET({
        endpoint: `v1/ot/schedule/web/${companyId}/${unitId}/pwa-get-all`,
        params: {
          otId,
          surgeryTypeId,
          timestamp,
          page,
          size,
          sort,
          ignorePaging,
        },
      });
      if (res.status) {
        dispatch({
          type: MY_SCHEDULE_ACTIONS.GET_MY_SCHEDULE_SUCCESS,
          payload: res.schedules,
          totalCount: res.total_count,
        });
        if (loader) loader(false);
      }
    } catch (error) {
      dispatch({
        type: "SET_TOASTER_DATA",
        notification: {
          message: error.message,
          title: "Error",
          type: "error",
        },
      });
      if (loader) loader(false);
    }
  };

export const getScheduleDetails =
  (unitId, scheduleId, loader) => async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};
    try {
      const res = await requestMethods.GET({
        endpoint: `/v1/ot/schedule/web/${companyId}/${unitId}/get-all-schedules`,
        params: {
          scheduleId,
        },
      });
      if (res.status) {
        dispatch(getRequestDetails(unitId, res.schedules[0].tentative_id));
        dispatch({
          type: MY_SCHEDULE_ACTIONS.GET_SCHEDULE_DETAILS,
          payload: res.schedules[0],
        });
        if (loader) loader(false);
      }
    } catch (error) {
      dispatch({
        type: "SET_TOASTER_DATA",
        notification: {
          message: error.message,
          title: "Error",
          type: "error",
        },
      });
      if (loader) loader(false);
    }
  };

export const clearMySchedule = () => {
  return {
    type: MY_SCHEDULE_ACTIONS.CLEAR_MY_SCHEDULE_STORE,
  };
};

export const updateOtStatus =
  (unitId, scheduleId, status, body, setLoader, onCancel) =>
  async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};
    try {
      const res = await requestMethods.PUT({
        endpoint: `v1/ot/schedule/web/${companyId}/${unitId}/update-schedule-status/${scheduleId}/${status}`,
        body,
      });
      if (res.status) {
        dispatch(getScheduleDetails(unitId, scheduleId, setLoader));
        dispatch(handleAlertSuccess(WORDINGS.STATUS_UPDATED_SUCCESSFULLY));
        if (setLoader) setLoader(false);
        onCancel();
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (setLoader) setLoader(false);
    }
  };

export const getBeds = (unitId, params, setLoader) => async (dispatch) => {
  const { company: { id: companyId = "" } = {} } =
    getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};
  try {
    const res = await requestMethods.GET({
      endpoint: `/v1/ot/bedsMaster/web/${companyId}/${unitId}/get-beds`,
      params,
    });
    if (res.status) {
      dispatch({
        type: MY_SCHEDULE_ACTIONS.GET_BED_LIST,
        payload: res.ot_beds,
      });
      if (setLoader) setLoader(false);
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));
    if (setLoader) setLoader(false);
  }
};

export const getPostOts = (unitId, otId, setLoader) => async (dispatch) => {
  const { company: { id: companyId = "" } = {} } =
    getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};
  try {
    const res = await requestMethods.GET({
      endpoint: `/v1/ot/operative-area/web/${companyId}/${unitId}/get-post-ot-areas/${otId}`,
    });
    if (res.status) {
      dispatch({
        type: MY_SCHEDULE_ACTIONS.GET_POST_OT_AREAS,
        payload: res.ot_operative_area_for_user,
      });
      if (setLoader) setLoader(false);
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));
    if (setLoader) setLoader(false);
  }
};
