import {
  CleaningEnd,
  CleaningStart,
  PatientRecieved,
  PatientTransferred,
  WheelIn,
  WheelOut,
} from "../assets/Icons/icons";
import { REQUEST_STATUS, SURGERY_STATUS, WORDINGS } from "./constants";
import { permissions } from "./permission";
import React from "react";

const iconSize = 35;

const labelStyle = {
  display: "flex",
  alignItems: "center",
  placeItems: "center",
  gap: "1rem",
  aspectRatio: "1/1",
  fontSize: "14px",
  height: "40px",
  width: "100%",
};

export const statusColorMap = {
  [REQUEST_STATUS.SCHEDULED]: {
    backgroundColor: "#CBEED8",
    color: "#32A05A",
  },
  [REQUEST_STATUS.PRE_OT]: {
    backgroundColor: "#C4FFDA",
    color: "#159D46",
  },
  [REQUEST_STATUS.WHEEL_IN]: {
    backgroundColor: "#8BBDFF",
    color: "#0060E0",
  },
  [REQUEST_STATUS.WHEEL_OUT]: {
    backgroundColor: "#F0CFF1",
    color: "#F231E8",
  },
  [REQUEST_STATUS.START_CLEANING]: {
    backgroundColor: "#AD7617",
    color: "#AD7617",
  },
  [REQUEST_STATUS.END_CLEANING]: {
    backgroundColor: "#FFFFC5",
    color: "#8E8617",
  },
  [REQUEST_STATUS.PATIENT_SHIFTED]: {
    backgroundColor: "#D1C9FF",
    color: "#7C66FF",
  },
  [REQUEST_STATUS.RESCHEDULED]: {
    backgroundColor: "#FFFFC5",
    color: "#8E8617",
  },
};

export const SURGERY_STATUS_OPTIONS = [
  {
    id: "patientRecievedAtPreOT",
    value: REQUEST_STATUS.PRE_OT,
    label: (
      <label style={labelStyle}>
        <PatientRecieved size={24} />
        {WORDINGS.PATIENT_RECIEVED_AT_PRE_OT}
      </label>
    ),
    privilege: permissions.SURGERY_STATUS_REAL_TIME_PRE_OP_UPDATE,
  },
  {
    id: "wheelIn",
    value: REQUEST_STATUS.WHEEL_IN,
    label: (
      <label style={labelStyle}>
        <WheelIn size={iconSize} />
        {WORDINGS.WHEEL_IN}
      </label>
    ),
    privilege: permissions.SURGERY_STATUS_REAL_TIME_SURGERY_UPDATE,
  },
  {
    id: "wheelOut",
    value: REQUEST_STATUS.WHEEL_OUT,
    label: (
      <label style={labelStyle}>
        <WheelOut size={iconSize} />
        {WORDINGS.WHEEL_OUT}
      </label>
    ),
    privilege: permissions.SURGERY_STATUS_REAL_TIME_SURGERY_UPDATE,
  },
  {
    id: "cleaningStart",
    value: REQUEST_STATUS.START_CLEANING,
    label: (
      <label style={labelStyle}>
        <CleaningStart size={iconSize} />
        {WORDINGS.CLEANING_START}
      </label>
    ),
    privilege: permissions.SURGERY_STATUS_REAL_TIME_CLEANING_UPDATE,
  },
  {
    id: "cleaningEnd",
    value: REQUEST_STATUS.END_CLEANING,
    label: (
      <label style={labelStyle}>
        <CleaningEnd size={iconSize} />
        {WORDINGS.CLEANING_END}
      </label>
    ),
    privilege: permissions.SURGERY_STATUS_REAL_TIME_CLEANING_UPDATE,
  },
  {
    id: "patientTransferred",
    value: REQUEST_STATUS.PATIENT_SHIFTED,
    label: (
      <label style={labelStyle}>
        <PatientTransferred size={44} />
        {WORDINGS.PATIENT_TRANSFERRED_FROM_RECOVERY_AREA}
      </label>
    ),
    privilege: permissions.SURGERY_STATUS_REAL_TIME_POST_OP_UPDATE,
  },
];

export const SURGERY_STATUS_LABELS_FOR_CARD = [
  {
    id: "patientRecievedAtPreOT",
    value: REQUEST_STATUS.PRE_OT,
    label: WORDINGS.PATIENT_RECIEVED,
  },
  {
    id: "wheelIn",
    value: REQUEST_STATUS.WHEEL_IN,
    label: WORDINGS.WHEEL_IN,
  },
  {
    id: "wheelOut",
    value: REQUEST_STATUS.WHEEL_OUT,
    label: WORDINGS.WHEEL_OUT,
  },
  {
    id: "cleaningStart",
    value: REQUEST_STATUS.START_CLEANING,
    label: WORDINGS.CLEANING_START,
  },
  {
    id: "cleaningEnd",
    value: REQUEST_STATUS.END_CLEANING,
    label: WORDINGS.CLEANING_END,
  },
  {
    id: "patientTransferred",
    value: REQUEST_STATUS.PATIENT_SHIFTED,
    label: WORDINGS.PATIENT_TRANSFERRED,
  },
  {
    id: "rescheduled",
    value: REQUEST_STATUS.RESCHEDULED,
    label: WORDINGS.RESCHEDULED,
  },
];

export const displayBookingStatus = {
  [REQUEST_STATUS.BOOKED]: SURGERY_STATUS.REQUESTED,
  [REQUEST_STATUS.SCHEDULED]: SURGERY_STATUS.SCHEDULED,
  [REQUEST_STATUS.PRE_OT]: SURGERY_STATUS.PRE_OT,
  [REQUEST_STATUS.WHEEL_IN]: SURGERY_STATUS.WHEEL_IN,
  [REQUEST_STATUS.WHEEL_OUT]: SURGERY_STATUS.WHEEL_OUT,
  [REQUEST_STATUS.PATIENT_SHIFTED]: SURGERY_STATUS.PATIENT_SHIFTED,
  [REQUEST_STATUS.RESCHEDULED]: SURGERY_STATUS.RESCHEDULED,
};
