import React from "react";
import { useSelector } from "react-redux";
import Card from "../../../components/Card";
import { removeUnderScore } from "../../../helpers/utils";
import { WORDINGS } from "../../../helpers/constants";
import styles from "./index.module.css";

const CrewTab = () => {
  const { requestDetails = {} } = useSelector((state) => state.bookingReducer);
  return requestDetails?.crew_data_details ? (
    <div className={styles["schedule-tab__crew-details"]}>
      {Object.keys(requestDetails?.crew_data_details)?.map((crewKey) =>
        requestDetails?.crew_data_details[crewKey].length > 1
          ? requestDetails?.crew_data_details[crewKey]?.map((data, index) => (
              <Card
                title={`${removeUnderScore(crewKey)} ${index + 1}`}
                key={index}
              >
                <div>{data?.name}</div>
              </Card>
            ))
          : requestDetails?.crew_data_details[crewKey].length === 1 && (
              <Card title={removeUnderScore(crewKey)} key={crewKey}>
                <div>
                  {requestDetails?.crew_data_details[crewKey].map(
                    (item) => item?.name
                  )}
                </div>
              </Card>
            )
      )}
    </div>
  ) : (
    <div className={styles["schedule-tab__no-item"]}>
      {WORDINGS.NO_CREW_DETAILS}
    </div>
  );
};

export default CrewTab;
