import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  BOOKING_TYPE,
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  LOG_TYPES,
  REQUEST_STATUS,
  SURGERY_STATUS,
  SWC,
  WORDINGS,
} from "../../../helpers/constants";
import scissors from "../../../assets/Icons/scissors-cut-01.svg";
import hospital from "../../../assets/Icons/hospital.svg";
import { FaStethoscope, FaStore } from "react-icons/fa";
import equimentIcon from "../../../assets/Icons/scissorsCut.svg";
import pharmacyListIcon from "../../../assets/Icons/medicalDoc.svg";
import equipmentDark from "../../../assets/Icons/cutDark.svg";
import prescriptionDark from "../../../assets/Icons/prescriptionDark.svg";
import NewButton from "../../../components/NewButton";
import Modal from "../../../components/Modal";
import styles from "./index.module.css";
import {
  PiSirenFill,
  PiSyringeLight,
  PiUser,
  PiVirusLight,
} from "react-icons/pi";
import {
  checkLogType,
  getItemFromLocalStorage,
  handleAlertError,
  joinClass,
  joinStrings,
  removeUnderScore,
} from "../../../helpers/utils";
import {
  MdOutlineAddShoppingCart,
  MdOutlineCalendarToday,
  MdOutlineLocationOn,
} from "react-icons/md";
import { LuCalendarCheck2, LuClock9, LuDroplets } from "react-icons/lu";
import { RiScissorsCutFill } from "react-icons/ri";
import { CssdKitList } from "../../NewMySchedules/ScheduleDetails/CssdKitList";
import { StatusUpdateModal } from "./StatusUpdateModal";
import {
  displayBookingStatus,
  statusColorMap,
  SURGERY_STATUS_OPTIONS,
} from "../../../helpers/surgeryStatusOptions";
import { permissions } from "../../../helpers/permission";

const ScheduleTab = ({ scheduleId }) => {
  const { scheduleDetails = {} } = useSelector(
    (state) => state.myScheduleReducer
  );
  const { requestDetails = {} } = useSelector((state) => state.bookingReducer);

  const { privileges = {} } =
    getItemFromLocalStorage(GLOBAL_CONSTANTS.USER) || {};

  const [isEquipments, toggleIsEquipments] = useState(false),
    [isPharmacySupplies, toggleIsPharmacySupplies] = useState(false),
    [isStandardPharmacyList, toggleIsStandardPharmacyList] = useState(false),
    [isMaterialStoreList, toggleIsMaterialStoreList] = useState(false),
    [IsCssdKitList, toggleIsCssdKitList] = useState(false),
    [statusUpdate, setStatusUpdate] = useState(false),
    [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const privilegesForStatusUpdate = [
    permissions.SURGERY_STATUS_REAL_TIME_PRE_OP_UPDATE,
    permissions.SURGERY_STATUS_REAL_TIME_SURGERY_UPDATE,
    permissions.SURGERY_STATUS_REAL_TIME_CLEANING_UPDATE,
    permissions.SURGERY_STATUS_REAL_TIME_POST_OP_UPDATE,
  ];

  const checkIsAnyOptions = () => {
    const excludedOptions = scheduleDetails?.logs?.map(
      (option) => option.log_type
    );
    return SURGERY_STATUS_OPTIONS?.filter(
      (option) =>
        privileges[option?.privilege] === true &&
        !excludedOptions?.includes(option.value)
    );
  };

  const hasPrivilege = useMemo(() => {
    return privilegesForStatusUpdate.some(
      (privilege) => privileges[privilege] === true
    );
  }, [privileges, privilegesForStatusUpdate]);

  const disabled = useMemo(() => {
    return (
      !checkIsAnyOptions().length > 0 ||
      moment(scheduleDetails?.surgery_start_time).isBefore(moment(), "day")
    );
  }, [scheduleDetails?.logs?.map, scheduleDetails]);

  const handleStatusBtn = () => {
    if (disabled && !checkIsAnyOptions().length > 0) {
      dispatch(
        dispatch(handleAlertError(WORDINGS.ALL_STATUS_HAVE_BEEN_UPDATED))
      );
    } else if (
      disabled &&
      moment(scheduleDetails?.surgery_start_time).isBefore(moment(), "day")
    ) {
      dispatch(
        handleAlertError(WORDINGS.STATUSES_CANT_BE_UPDATED_FOR_PREVIOUS_DATES)
      );
    } else {
      setStatusUpdate(true);
    }
  };

  return (
    <div className={styles["schedule-tab"]}>
      {isEquipments && (
        <Modal
          isOpen={isEquipments}
          onCancel={() => toggleIsEquipments(false)}
          title={
            <label className={styles["schedule-tab__modal-title"]}>
              {WORDINGS.EQUIPMENTS}
              <img src={equipmentDark} alt="equipments" />
            </label>
          }
          closeButton
        >
          <div className={styles["schedule-tab__equipments-modal"]}>
            <div className={styles["schedule-tab__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.EQUIPMENTS}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {scheduleDetails?.ot_equipment_models?.length > 0 ? (
              Object.values(scheduleDetails?.ot_equipment_models)?.map(
                (item, index) => (
                  <div
                    key={index}
                    className={styles["schedule-tab__equipments-list"]}
                  >
                    <div>{item?.name}</div>
                    <div>{item?.equipment_count}</div>
                  </div>
                )
              )
            ) : (
              <div className={styles["schedule-tab__no-item"]}>
                {WORDINGS.NO_EQUIPMENTS_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isPharmacySupplies && (
        <Modal
          isOpen={isPharmacySupplies}
          onCancel={() => toggleIsPharmacySupplies(false)}
          title={
            <label className={styles["schedule-tab__modal-title"]}>
              {WORDINGS.ADDITIONAL_ITEMS}
              <MdOutlineAddShoppingCart fontSize={22} />
            </label>
          }
          closeButton
        >
          <div className={styles["schedule-tab__equipments-modal"]}>
            <div className={styles["schedule-tab__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.VENDOR}</div>
              <div>{GLOBAL_CONSTANTS.ITEM}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {scheduleDetails?.pharmacy_special_request.length > 0 ? (
              Object.values(scheduleDetails?.pharmacy_special_request)?.map(
                (item, index) => (
                  <div
                    key={index}
                    className={styles["schedule-tab__equipments-list"]}
                  >
                    <div>{item?.vendor}</div>
                    <div>{item?.item}</div>
                    <div>{item?.quantity}</div>
                  </div>
                )
              )
            ) : (
              <div className={styles["schedule-tab__no-item"]}>
                {WORDINGS.NO_ADDITIONAL_ITEMS_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isStandardPharmacyList && (
        <Modal
          isOpen={isStandardPharmacyList}
          onCancel={() => toggleIsStandardPharmacyList(false)}
          title={
            <label className={styles["schedule-tab__modal-title"]}>
              {WORDINGS.STANDARD_PHARMACY_LIST}
            </label>
          }
          closeButton
        >
          <div className={styles["schedule-tab__equipments-modal"]}>
            <div className={styles["schedule-tab__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.NAME}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {scheduleDetails?.pharmacy_standard_list.length > 0 ? (
              scheduleDetails?.pharmacy_standard_list?.map((item, index) => (
                <div
                  key={index}
                  className={styles["schedule-tab__equipments-list"]}
                >
                  <div>{item?.name}</div>
                  <div>{item?.quantity}</div>
                </div>
              ))
            ) : (
              <div className={styles["schedule-tab__no-item"]}>
                {WORDINGS.NO_STANDARD_PHARMACY_LIST_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isMaterialStoreList && (
        <Modal
          isOpen={isMaterialStoreList}
          onCancel={() => toggleIsMaterialStoreList(false)}
          title={
            <label className={styles["schedule-tab__modal-title"]}>
              {WORDINGS.MATERIAL_STORE_LIST}
            </label>
          }
          closeButton
        >
          <div className={styles["schedule-tab__equipments-modal"]}>
            <div className={styles["schedule-tab__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.NAME}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {scheduleDetails?.standard_material_list?.length > 0 ? (
              scheduleDetails?.standard_material_list?.map((item, index) => (
                <div
                  key={index}
                  className={styles["schedule-tab__equipments-list"]}
                >
                  <div>{item?.name}</div>
                  <div>{item?.quantity}</div>
                </div>
              ))
            ) : (
              <div className={styles["schedule-tab__no-item"]}>
                {WORDINGS.NO_STANDARD_MATERIAL_LIST_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {IsCssdKitList && (
        <Modal
          isOpen={IsCssdKitList}
          onCancel={() => toggleIsCssdKitList(false)}
          title={
            <label className={styles["schedule-tab__modal-title"]}>
              {WORDINGS.CSSD_KIT_LIST}
            </label>
          }
          closeButton
        >
          <CssdKitList data={scheduleDetails} />
        </Modal>
      )}
      {statusUpdate && (
        <Modal
          isOpen={statusUpdate}
          onCancel={() => setStatusUpdate(false)}
          title={
            <label className={styles["schedule-tab__modal-title"]}>
              {WORDINGS.SELECT_SURGERY_STATUS}
            </label>
          }
          closeButton
        >
          <StatusUpdateModal
            scheduleId={scheduleId}
            otId={scheduleDetails.ot.id}
            setLoading={setLoading}
            onCancel={() => setStatusUpdate(false)}
            isSearchable={false}
          />
        </Modal>
      )}
      <div className={styles["schedule-tab__warning-status-container-parent"]}>
        <div className={styles["schedule-tab__warning-status-container"]}>
          <p>{scheduleDetails?.surgery_number}</p>
          {scheduleDetails?.surgery_classification ===
            BOOKING_TYPE.EMERGENCY && (
            <PiSirenFill fontSize={24} color="#B71F1F" />
          )}
          <label
            style={{
              backgroundColor:
                statusColorMap[scheduleDetails?.request_status]
                  ?.backgroundColor,
              color: statusColorMap[scheduleDetails?.request_status]?.color,
            }}
          >
            {displayBookingStatus[scheduleDetails?.request_status]}
          </label>
        </div>

        {hasPrivilege && (
          <NewButton
            smallTabButtonActive
            disableWithFuntionality={disabled}
            className={styles["schedule-tab__status-btn"]}
            onClick={handleStatusBtn}
          >
            {WORDINGS.UPDATE_STATUS}
          </NewButton>
        )}
      </div>
      <div className={styles["schedule-tab__calender-loc-container"]}>
        <div className={styles["schedule-tab__calendar"]}>
          <span className={styles["schedule-tab__icon-container"]}>
            <MdOutlineCalendarToday fontSize={20} color="#155697" />
          </span>
          {moment(scheduleDetails?.surgery_start_time).format(
            DATE_TIME_FORMAT.Do_MMMM_YYYY
          )}
        </div>
        <div className={styles["schedule-tab__location"]}>
          <span className={styles["schedule-tab__icon-container"]}>
            <MdOutlineLocationOn fontSize={20} color="#155697" />
          </span>
          <p>
            {requestDetails?.requested_ot?.name} (
            {scheduleDetails?.booking_type === BOOKING_TYPE.NORMAL
              ? WORDINGS.LISTED_HOURS
              : WORDINGS.NON_LISTED_HOURS}
            )
          </p>
        </div>
      </div>

      <div
        className={joinClass(
          styles["schedule-tab__patient-details-container"],
          scheduleDetails?.swc?.name === SWC.CLEAN
            ? styles["schedule-tab__box-shadow-clean"]
            : styles["schedule-tab__box-shadow-non-clean"]
        )}
      >
        <div
          className={styles["schedule-tab__patient-details-container-header"]}
        >
          <div className={styles["schedule-tab__patient-details-avatar"]}>
            <PiUser fontSize={38} color="#155697" />
          </div>
          <div className={styles["schedule-tab__patient-details"]}>
            {scheduleDetails?.patient_name && (
              <p>
                {scheduleDetails?.patient_name}
                {scheduleDetails?.age && (
                  <span> ( {scheduleDetails?.age} )</span>
                )}
              </p>
            )}
            {scheduleDetails?.patient_mrn && (
              <p>{`${WORDINGS.MR_NUMBER}: ${scheduleDetails?.patient_mrn}`}</p>
            )}
            {scheduleDetails?.uhid && (
              <p>{`${WORDINGS.UHID}: ${scheduleDetails?.uhid}`}</p>
            )}
          </div>
        </div>
        <div
          className={styles["schedule-tab__patient-details-container-footer"]}
        >
          {scheduleDetails?.swc && (
            <label
              className={
                scheduleDetails?.swc.name === SWC.CLEAN
                  ? styles["skyblue-label"]
                  : styles["red-label"]
              }
            >
              {scheduleDetails?.swc.name}
            </label>
          )}
          {checkLogType(
            scheduleDetails?.booking_confirmation_logs,
            LOG_TYPES.PAC
          ) && <label>{WORDINGS.PAC_REQUIRED}</label>}
        </div>
      </div>
      {checkLogType(
        scheduleDetails?.booking_confirmation_logs,
        LOG_TYPES.BLOOD_BANK
      ) && (
        <label className={styles["schedule-tab__day"]}>
          <LuDroplets fontSize={24} color="#B71F1F" />
          {WORDINGS.BLOOD_BANK_READINESS}:<span>{WORDINGS.REQUIRED}</span>
        </label>
      )}
      {scheduleDetails?.type_of_anesthesia && (
        <label className={styles["schedule-tab__day"]}>
          <PiSyringeLight fontSize={24} />
          {WORDINGS.ANAESTHESIA}:
          <span>{joinStrings(scheduleDetails?.type_of_anesthesia)}</span>
        </label>
      )}

      {scheduleDetails?.pre_existing_infections && (
        <label className={styles["schedule-tab__day"]}>
          <PiVirusLight fontSize={24} />
          {WORDINGS.PRE_EXISTING_INFECTIONS}:
          <span>
            {scheduleDetails?.pre_existing_infections.name === "Others"
              ? scheduleDetails?.pre_existing_infections.notes
              : scheduleDetails?.pre_existing_infections.name}
          </span>
        </label>
      )}

      <div className={styles["schedule-tab__detail"]}>
        <section>
          <label>{WORDINGS.SURGERY_DETAILS}</label>
          <div className={styles["schedule-tab__details"]}>
            <div>
              <LuCalendarCheck2 size={20} color="#0E376E" />
              <label className={styles["font-red"]}>
                {`${removeUnderScore(
                  scheduleDetails?.booking_mode
                )},${removeUnderScore(
                  scheduleDetails?.surgery_classification
                )},${removeUnderScore(scheduleDetails?.surgery_type)} ${
                  GLOBAL_CONSTANTS.SURGERY
                }`}
              </label>
            </div>
            <div>
              <img src={hospital} alt="Speciality" />
              <label>{scheduleDetails?.department?.name}</label>
            </div>
            <div
              className={
                scheduleDetails?.types_of_surgeries?.length > 1
                  ? styles["schedule-tab__surgery-name"]
                  : ""
              }
            >
              <img src={scissors} alt="surgery" />
              {scheduleDetails?.types_of_surgeries?.length > 1 ? (
                <label>
                  {scheduleDetails?.types_of_surgeries?.map(
                    (surgery, index) => (
                      <div key={index}>
                        {index + 1}. {surgery.name}
                      </div>
                    )
                  )}
                </label>
              ) : (
                <label>
                  {scheduleDetails?.types_of_surgeries?.map(
                    (surgery, index) => (
                      <div key={index}>{surgery.name}</div>
                    )
                  )}
                </label>
              )}
            </div>
            <div>
              <FaStethoscope size={20} color="#0E376E" />
              <label>
                {scheduleDetails?.crew_details?.chief_surgeon[0]?.name}
              </label>
            </div>
          </div>
        </section>
        <section>
          <label>{WORDINGS.TIME_SLOT}</label>
          <div className={styles["schedule-tab__slot-details"]}>
            <div className={styles["schedule-tab__clock-box"]}>
              <LuClock9 fontSize={20} color="#155697" />
              {`${moment(scheduleDetails?.start).format(
                DATE_TIME_FORMAT.hh_mm_A
              )} - ${moment(scheduleDetails?.end).format(
                DATE_TIME_FORMAT.hh_mm_A
              )}`}
            </div>
            <div className={styles["schedule-tab__time-slot-slots"]}>
              <label>{`${GLOBAL_CONSTANTS.PROCEDURE_TIME}: ${
                (scheduleDetails?.surgery_end_time -
                  scheduleDetails?.surgery_start_time) /
                60000
              } ${GLOBAL_CONSTANTS.MINS}`}</label>
              <label>{`${GLOBAL_CONSTANTS.CLEANING_TIME}: ${
                scheduleDetails?.buffer_time / 60000
              } ${GLOBAL_CONSTANTS.MINS}`}</label>
            </div>
          </div>
        </section>
        {scheduleDetails?.notes && (
          <section>
            <label>{WORDINGS.NOTES}</label>
            <div className={styles["schedule-tab__slot-details"]}>
              {scheduleDetails?.notes}
            </div>
          </section>
        )}
        <div className={styles["schedule-tab__detail-bottom-container"]}>
          <label>{WORDINGS.OTHER_DETAILS}</label>
          <div
            className={styles["schedule-tab__detail-bottom-container-buttons"]}
          >
            <NewButton modalButton onClick={() => toggleIsEquipments(true)}>
              {
                <div className={styles["schedule-tab__modal-buttons"]}>
                  <img src={equimentIcon} alt="Equiments_icon" />
                  <label>{WORDINGS.EQUIPMENTS}</label>
                </div>
              }
            </NewButton>
            <NewButton
              modalButton
              onClick={() => toggleIsPharmacySupplies(true)}
            >
              {
                <div className={styles["schedule-tab__modal-buttons"]}>
                  <MdOutlineAddShoppingCart fontSize={22} />
                  <label>{WORDINGS.ADDITIONAL_ITEMS}</label>
                </div>
              }
            </NewButton>
            <NewButton
              modalButton
              onClick={() => toggleIsStandardPharmacyList(true)}
            >
              {
                <div className={styles["schedule-tab__modal-buttons"]}>
                  <img src={pharmacyListIcon} alt="Equiments_icon" />
                  <label>{WORDINGS.STANDARD_PHARMACY_LIST}</label>
                </div>
              }
            </NewButton>
            <NewButton
              modalButton
              onClick={() => toggleIsMaterialStoreList(true)}
            >
              {
                <div className={styles["schedule-tab__modal-buttons"]}>
                  <FaStore fontSize={24} />
                  <label>{WORDINGS.MATERIAL_STORE_LIST}</label>
                </div>
              }
            </NewButton>
            <NewButton modalButton onClick={() => toggleIsCssdKitList(true)}>
              {
                <div className={styles["schedule-tab__modal-buttons"]}>
                  <RiScissorsCutFill fontSize={24} />
                  <label>{WORDINGS.CSSD_KIT_LIST}</label>
                </div>
              }
            </NewButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTab;
